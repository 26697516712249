@use 'variables' as *;

$border-style: 1px solid color('grey light');
$border-radius: 4px;
$row-side-padding: 24px;
$row-side-padding--reduced: 4px;

.list {
  margin-top: 16px;

  &__head {
    padding: 13px $row-side-padding;
    font-size: 11px;
    font-weight: 400;
    color: $color-gray;
    text-align: left;
    text-transform: uppercase;

    &.text-right {
      text-align: right;
    }
  }

  &__rows {
    border: $border-style;
    border-radius: $border-radius;

    .list--with-background & {
      background-color: color('bg');
    }
  }
  &__row {
    padding: 10px $row-side-padding;
    border-bottom: $border-style;
    font-size: 12px;
    line-height: 15px;
    font-weight: 500;
    color: color('black');

    &:last-child {
      border: 0;
    }
  }

  &__head,
  &__row {
    &--with-action {
      padding-right: $row-side-padding--reduced;
    }
  }

  .display-value {
    display: flex;
    align-items: center;
  }
}

.list-disc {
  padding-left: 24px;
  list-style: disc;
}
