@import "variables";

$border-table-style: 1px solid $color-gray-light;
.table {
  width: 100%;

  & > :not(:first-child) {
    border-top: none;
  }

  .link {
    color: $color-primary;
    font-weight: 600;
    font-size: 12px;
    cursor: pointer;
    user-select: none;

    &--no-transform {
      text-transform: none;
    }

    &:hover {
      color: darken($color-primary, 20%);
    }
  }

  th,
  td {
    padding: rem(20) rem(4) rem(20) rem(20);

    &:last-child {
      padding-right: rem(20);
    }
  }

  th {
    font-weight: 400;
    color: $color-gray;
    text-align: left;
    font-size: 11px;
    text-transform: uppercase;
  }

  tbody, thead {
    td {
      font-weight: 400;
      font-size: 12px;
      color: color(text);
    }
  }

  tbody {
    box-shadow: 0 0 0 1px $color-gray-light;
    border-radius: $border-radius;

    td {
      border-bottom: $border-table-style;
    }
  }

  tbody {
    box-shadow: none;
    border-radius: 0px;

    td:first-child {
      border-left: $border-table-style;
    }

    td:last-child {
      border-right: $border-table-style;
    }

    td:first-child {
      border-top-left-radius: $border-radius;
    }

    td:last-child {
      border-top-right-radius: $border-radius;
    }

    td:first-child {
      border-bottom-left-radius: $border-radius;
    }

    td:last-child {
      border-bottom-right-radius: $border-radius;
    }

    tr {
      &:first-child {
        td {
          border-top: $border-table-style;
        }
      }
    }
  }
}

tfoot td {
  color: inherit !important;
}
