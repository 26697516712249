@media print {
  dm-ui-pagination,
  dm-mms-layout-header {
    display: none !important;
  }

  .table {
    * {
      @media print {
        color: #000 !important;
      }
    }
  }
}
