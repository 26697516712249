@use 'variables' as *;

i[ng-reflect-ngb-tooltip] {
  cursor: pointer;
  color: color('primary');
}

.tooltip-inner {
  padding: 8px;
  background: color('tooltip background');
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;

  .tooltip.wide & {
    text-align: left;

    @include media('md') {
      max-width: 470px;
    }
  }

  .tooltip.error & {
    background: color('danger');
  }

  .bs-tooltip-bottom-end &,
  .bs-tooltip-top-end & {
    transform: translateX(15px);
  }
  .bs-tooltip-bottom-start &,
  .bs-tooltip-top-start &{
    transform: translateX(-15px);
  }

  a {
    color: color('primary');
  }
}

.tooltip-arrow {
  border-color: color('tooltip background');

  .tooltip.error & {
    border-color: color('danger');
  }

  &::before {
    .bs-tooltip-top & {
      border-top-color: inherit;
    }
    .bs-tooltip-end & {
      border-right-color: inherit;
    }
    .bs-tooltip-bottom & {
      border-bottom-color: inherit;
    }
    .bs-tooltip-start & {
      border-left-color: inherit;
    }
  }
}

.bs-tooltip-auto {
  &[data-popper-placement^='top'] {
    @extend .bs-tooltip-top;
  }
  &[data-popper-placement^='right'] {
    @extend .bs-tooltip-end;
  }
  &[data-popper-placement^='bottom'] {
    @extend .bs-tooltip-bottom;
  }
  &[data-popper-placement^='left'] {
    @extend .bs-tooltip-start;
  }
}
