@use 'variables' as *;

.color {
  &-black {
    color: color('black') !important;
  }
  &-warning {
    color: color('warning') !important;
  }

  &-primary {
    color: color('primary') !important;
  }

  &-secondary {
    color: color('secondary') !important;
  }

  &-gray {
    color: color('grey') !important;
  }

  &-danger {
    color: color('danger') !important;
  }

  &-success {
    color: color('success') !important;
  }

  &-danger-dark {
    color: color('danger dark') !important;
  }

  &-kashmir {
    color: color('secondary') !important;
  }
}
