.text-center {
  text-align: center;
}
.whitespace-nowrap{
  white-space: nowrap;
}
.text-right {
  text-align: right;
}

.fw-600 {
  font-weight: 600 !important;
}

.font-italic {
  font-style: italic;
}

.fw-300 {
  font-weight: 300 !important;
}
.fw-500 {
  font-weight: 500 !important;
}
.fw-400 {
  font-weight: 400 !important;
}
.fw-700 {
  font-weight: 700 !important;
}
.info-label {
  margin: 8px 0 24px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.text-sm {
  font-size: 12px !important;
}
.text-md {
  font-size: 14px !important;
}
.text-lg {
  font-size: 18px !important;
}
.text-xl {
  font-size: 18px !important;
}
