.sticky-element {
  position: sticky;
  top: 0;
  z-index: 5;
  transition: top 0.2s;

  @media print {
    position: relative !important;
    top: 0 !important;
  }
}
