$i-addbooking: "\ea01";
$i-anchor-2: "\ea02";
$i-anchor: "\ea03";
$i-arrow-depth: "\ea04";
$i-arrow-double-right: "\ea05";
$i-arrow-export: "\ea06";
$i-arrow-left: "\ea07";
$i-arrow-right: "\ea08";
$i-arrow-x: "\ea09";
$i-arrow-y: "\ea0a";
$i-bank: "\ea0b";
$i-berths: "\ea0c";
$i-bilge: "\ea0d";
$i-bill: "\ea0e";
$i-bin: "\ea0f";
$i-boat-2: "\ea10";
$i-boat-front: "\ea11";
$i-boat: "\ea12";
$i-book: "\ea13";
$i-booking: "\ea14";
$i-business: "\ea15";
$i-calendar-add: "\ea16";
$i-calendar: "\ea17";
$i-card-2: "\ea18";
$i-card: "\ea19";
$i-catamaran: "\ea1a";
$i-check-circle: "\ea1b";
$i-check-small: "\ea1c";
$i-check: "\ea1d";
$i-chevron-left: "\ea1e";
$i-chevron_down: "\ea1f";
$i-close-circle: "\ea20";
$i-close: "\ea21";
$i-cloud: "\ea22";
$i-coins: "\ea23";
$i-comment: "\ea24";
$i-compass: "\ea25";
$i-contract: "\ea26";
$i-copy: "\ea27";
$i-diamond: "\ea28";
$i-dmarin-logo-signet: "\ea29";
$i-document-2: "\ea2a";
$i-document: "\ea2b";
$i-documents: "\ea2c";
$i-download: "\ea2d";
$i-drop-outline: "\ea2e";
$i-drop: "\ea2f";
$i-dropdown-thin: "\ea30";
$i-dropdown: "\ea31";
$i-edit: "\ea32";
$i-envelope-notification: "\ea33";
$i-envelope: "\ea34";
$i-exclamation-mark-circle: "\ea35";
$i-eye-invisible: "\ea36";
$i-eye: "\ea37";
$i-form-info: "\ea38";
$i-gear: "\ea39";
$i-globe: "\ea3a";
$i-height: "\ea3b";
$i-home: "\ea3c";
$i-image-download: "\ea3d";
$i-info-2: "\ea3e";
$i-info: "\ea3f";
$i-invisible: "\ea40";
$i-lightning: "\ea41";
$i-list: "\ea42";
$i-logo: "\ea43";
$i-mail-2: "\ea44";
$i-mail-notification: "\ea45";
$i-mail: "\ea46";
$i-marina-task: "\ea47";
$i-mobile: "\ea48";
$i-money-notification: "\ea49";
$i-money-refresh: "\ea4a";
$i-money-tick: "\ea4b";
$i-mooring: "\ea4c";
$i-more-squares: "\ea4d";
$i-more: "\ea4e";
$i-motorboat: "\ea4f";
$i-office: "\ea50";
$i-out: "\ea51";
$i-outside_link: "\ea52";
$i-phone: "\ea53";
$i-pin: "\ea54";
$i-plug-in: "\ea55";
$i-plus-circle: "\ea56";
$i-plus: "\ea57";
$i-pricing: "\ea58";
$i-profile: "\ea59";
$i-radio: "\ea5a";
$i-refresh: "\ea5b";
$i-sailing-boat: "\ea5c";
$i-search: "\ea5d";
$i-send: "\ea5e";
$i-service: "\ea5f";
$i-star-outline: "\ea60";
$i-star-plus: "\ea61";
$i-star: "\ea62";
$i-success: "\ea63";
$i-summary-2: "\ea64";
$i-summary: "\ea65";
$i-temperature: "\ea66";
$i-time: "\ea67";
$i-timer: "\ea68";
$i-transfer: "\ea69";
$i-upload: "\ea6a";
$i-user-2: "\ea6b";
$i-user-corporate: "\ea6c";
$i-user-full: "\ea6d";
$i-user: "\ea6e";
$i-users: "\ea6f";
$i-wallet-2: "\ea70";
$i-wallet: "\ea71";
$i-width: "\ea72";

