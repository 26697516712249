.break-word {
  word-break: break-word;
}

.hidden {
  visibility: hidden;
}

.vertical-align-middle {
  vertical-align: middle;
}

.border-left-0 {
  border-left: 0 !important;
}

.flex-no-wrap {
  flex-wrap: nowrap;
}
