@use 'variables' as *;

.dropdown {
  &-menu {
    min-height: 30px;
  }

  &-toggle {
    &:after {
      transition: transform $time-transition;
      border: none;
      content: $i-dropdown-thin;
      font-family: font('icomoon');
      vertical-align: -0.075em;
      color: color('primary');
    }

    &[aria-expanded='true'] {
      &:after {
        transform: rotate(180deg);
      }
    }
  }
}
