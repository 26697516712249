@use 'variables' as *;

.badge {
  padding: 2px 8px;
  border-radius: 100px;
  font-size: 12px;
  text-transform: none;
  font-weight: 600;
  letter-spacing: 0.2px;
  color: color('white');
  white-space: nowrap;

  &--size-small {
    font-size: 11px;
    line-height: 16px;
  }

  &--color {
    &-success {
      background-color: color('badge success');
    }

    &-error {
      background-color: color('danger dark');
    }

    &-new {
      background-color: color('secondary');
    }
  }
}
