@use 'variables' as *;

.tooltip-inner {
  color: color('tooltip text');
  background: color('tooltip background');
}

.tooltip-arrow {
  &::before {
    .bs-tooltip-top & {
      border-top-color: color('tooltip background');
    }
    .bs-tooltip-end & {
      border-right-color: color('tooltip background');
    }
    .bs-tooltip-bottom & {
      border-bottom-color: color('tooltip background');
    }
    .bs-tooltip-start & {
      border-left-color: color('tooltip background');
    }
  }
}

