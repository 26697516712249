@use "variables" as *;

google-map .gm-style {
  div[role="dialog"]{
    .gm-style-iw-chr{
      display: none;
    }
  }
  .gm-style-iw-c{

    @include theme($theme-new-customer) {
     border-radius: 0;
    }
  }
  .gm-style-iw {
    font-family: font('default') !important;
    font-size: 14px !important;
    padding: 16px;

    @include theme($theme-customer, $theme-booking) {
      border: 1px solid color('map popup border') !important;
    }
  }

  .gm-style-iw-d {
    overflow: auto !important;
    padding: 0 !important;
  }

  .gm-style-iw-t::after {
    background: color('map popup background') !important;

    @include theme($theme-customer, $theme-booking) {
      border-left: 1px solid color('map popup border') !important;
      border-bottom: 1px solid color('map popup border') !important;
    }
  }

  .gm-style .gm-style-iw {
    padding: 16px;
  }

  button.gm-ui-hover-effect {
    visibility: hidden;
  }

  .gm-style-iw-tc {
    &:before {
      @include theme($theme-customer, $theme-booking) {
        clip-path: polygon(0% 0%, 50% 100%, 100% 0%);
        content: '';
        height: 13px;
        background: color('map popup border');
        left: -1px;
        position: absolute;
        top: -1px;
        width: 27px;
      }
    }
  }
}
