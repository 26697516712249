@import 'dropdown';

ngb-typeahead-window {
  &.dropdown-menu {
    @include theme($theme-customer) {
      max-height: 200px;
      overflow: auto;
      overflow-x: hidden;
    }
  }
}
.no-gutters {
  margin-right: 0;
  margin-left: 0;

  > .col,
  > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}
.modal-full{
  width: 100% !important;
}
