@import 'variables';

.pagination {
  margin-bottom: 0;

  .page-item {
    margin-right: 8px;
    @include theme($theme-customer) {
      a {
        background: #FFFFFF;
        border: 1px solid #E4E6F0;
        box-sizing: border-box;
        border-radius: 4px;
        width: 32px;
        height: 32px;
        font-weight: 500;
        font-size: 12px;
        padding: 0px;
        line-height: 32px;
        text-align: center;
        color: $color-iron;
        .i-dropdown{
          line-height: 32px;
        }
        &:focus{
          box-shadow: none !important;
        }
      }
      &.active a {
        border-color: color(primary);
        background: color(primary);
      }

    }
    @include theme($theme-mms) {
      a {
        display: block;
        width: 44px;
        height: 44px;
        border-radius: 50% !important;
        border: 1px solid $color-gray-light;
        color: $color-kashmir;
        font-size: 14px;
        font-weight: 700;
        line-height: 42px;
        text-align: center;
        padding: 0;
      }
    }
    @include theme($theme-mms) {
      &.active a {

          color: white !important;
        }
      }
  }
}
