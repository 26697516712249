$border-radius: 12px;

.modal {
  &-header {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }
  &-footer {
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
  &-content {
    border-radius: $border-radius;
  }
}
