@use 'variables' as *;

.color {
  &-grey {
    color: color('grey')
  }

  &-alabaster {
    color: $color-alabaster;
  }

  &-iron-light {
    color: transparentize(color(iron), .2);
  }
}
