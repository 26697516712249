@use 'variables' as *;

a {
	cursor: pointer;
}

.link {
	display: inline-flex;
  align-items: center;
	transition: color $time-transition;
	cursor: pointer;
	user-select: none;

	&--color {
		&-primary {
			color: color('primary');
		}

		&-secondary {
			color: color('secondary');
		}

		&-white {
			color: color('white');

			@include hover {
				color: color('primary');
			}
		}
	}

	&--size {
		&-tiny {
			font-size: 12px;
		}
    &-medium {
      font-size: 14px;
    }
	}

  [class^='i-'],
  [class*=' i-'] {
    margin-right: 4px;
  }
}
