@use 'variables' as *;

.i {
  &-oval {
    @include square(40px);

    display: inline-block;
    margin-right: rem(8);
    border-radius: 50%;
    background-color: color('primary');
    color: white;
    font-size: 20px;
    line-height: 40px;
    text-align: center;
    vertical-align: middle;

    &--light {
      background-color: #d0e2fb;
      color: color('primary');
    }

    &--border {
      flex: 0 0 auto;
      width: 48px;
      height: 48px;
      border: 4px solid #d0e2fb;
    }
  }

  &-size {
    &-m {
      font-size: 16px;
    }
    &-l {
      font-size: 24px;
    }
  }

  &-add {
    &:before {
      content: $i-close;
      display: inline-block;
      transform: rotate(45deg);
    }
  }
  &-arrow-down {
    &:before {
      content: $i-arrow-left;
      display: inline-block;
      transform: rotate(-90deg);
    }
  }
}
