@use 'variables' as *;

.card {
  display: block;
  padding: 8px 16px 16px;
  border-radius: 12px;
  background: color('white');
  box-shadow: 0px 4px 24px transparentize(color('white shadow'), 0.25);

  @include media('sm') {
    padding: 16px 24px 32px;
  }

  &__header {
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 24px;
    color: color('grey');
  }
}

.card-bordered {
  border: 1px solid color('formBorder');

  .card-header {
    padding: 16px 32px;
    background: color('bg');
  }

  .card-body {
    padding: 8px 32px;
  }
}
