@use 'variables' as *;

.typo {
  &-body {
    &-m {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }

    &-l {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.2px
    }
  }

  &-header {
    &-s {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
    }

    &-md-l {
      @include media-breakpoint-up(md) {
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 26px;
      }
    }

    &-l {
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 30px;
    }

    &-md-xl {
      @include media-breakpoint-up(md) {
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        line-height: 36px;
      }
    }

    &-xxl {
      font-size: 36px;
      font-style: normal;
      font-weight: 600;
      line-height: 50px;
    }
  }
}
