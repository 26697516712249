@use 'variables' as *;

$btn-icon-shadow: 0px 2px 5px 0px rgb(229, 232, 234);

.btn {
  border-radius: 4px;
  transition: color $time-transition, background $time-transition, transform $time-transition,
    box-shadow $time-transition;
  position: relative;
  overflow: hidden;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  min-height: 48px;
  font-weight: 700;
  outline: none;
  box-shadow: none;
  padding: 14px 36px;
  z-index: 0;
  border: 0;
  vertical-align: middle;
  display: inline-block;

  & > i {
    display: inline-block;
    vertical-align: middle;
    margin-right: 7px;
    line-height: 0;

    @include theme-not($theme-customer) {
      font-size: 20px;
    }

    @include theme($theme-customer) {
      font-size: 24px;
    }
  }

  &-full {
    width: 100%;
  }
  &-outline {
    background: transparent;
    border-style: solid;
    border-radius: 4px;
    border-width: 1px;
    &:before {
      background: white !important;
      opacity: 1 !important;
    }

    &:hover {
      &:before {
        opacity: 0;
      }
    }
    &.btn-white {
      border-color: white;
      color: white;
      &:hover {
        color: black;
      }
    }
  }
  &-sm {
    padding: 7px 12px;
    min-height: 40px;
    line-height: 26px;
  }

  &-wide {
    padding-left: 34px !important;
    padding-right: 34px !important;
  }

  &-narrow {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  &-tiny {
    padding: 2px 4px;
    min-height: 20px;
    font-size: 12px;
    line-height: 16px;

    &--with-spinner {
      padding: 4px;
    }

    i, span[class*=i] {
      margin: 0 4px 0 0;
      font-size: 16px;
      line-height: 16px;
      vertical-align: -4px;
    }
  }

  &-icon {
    padding: 0;
    width: 20px;
    height: 20px;
    min-height: 20px;

    &:before {
      display: none;
    }
  }

  &--icon-only {
    & > i {
      margin-right: 0;
    }
  }

  &--icon-with-border {
    width: 28px;
    height: 28px;
    min-height: 28px;
    padding: 0;
    text-align: center;
    border-radius: 4px;
    box-shadow: $btn-icon-shadow;
    color: color('grey');

    & > i {
      font-size: 20px;
      line-height: 28px;
    }
  }

  &:before {
    z-index: -1;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    transform: translateX(-105%);
    background: color('secondary');
    pointer-events: none;
    transition: transform $time-transition;
  }

  &:active {
    transform: translateY(1px);
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &-primary-negative {
    background-color: color('white');
    color: color('primary');

    &--transparent {
      background-color: transparent;
    }

    &:before {
      background: color('primary');
      opacity: 1;
    }

    @include hover {
      color: color('white');
    }

    &[aria-expanded='true'] {
      color: color('white');
    }
  }

  &-primary {
    background: color('gradient primary');
    color: color('white') !important;

    &:before {
      background: color('primary');
    }
  }
  &-danger-negative {
    background-color: color('white');
    color: color('danger dark');

    &--transparent {
      background-color: transparent;
    }

    &:before {
      background: color('danger dark');
      opacity: 1;
    }

    @include hover {
      color: color('white');
    }

    &[aria-expanded='true'] {
      color: color('white');
    }
    @include theme($theme-mms) {
      color: color('danger');

      &:before {
        background: color('danger');
      }
    }
  }
  &-secondary {
    background: transparent;
    color: color('primary');
    padding: 14px 36px;
    border: 1px solid color('primary') !important;

    &.btn-sm {
      padding: 6px 12px;
    }

    &:before {
      opacity: 1;
      background: color('primary');
    }

    @include hover {
      background: transparent;
      color: color('white');
    }
  }

  &-secondary-negative {
    background-color: color('white');
    color: color('secondary');

    &:before {
      background: color('secondary');
      opacity: 1;
    }

    @include hover {
      color: color('white');
    }
  }

  &-tertiary,
  &-link {
    @include theme($theme-customer) {
      color: color('primary') !important;

      &:before {
        background: color('grey light');
      }
    }

    @include theme($theme-mms) {
      color: color('grey');

      &:before {
        background: color('grey');
      }
    }
  }

  &-danger {
    background: color('danger dark');
    color: white;

    &:before {
      background: darken(color('danger dark'), 10%);
    }

  }

  &-block {
    width: 100%;
    display: block;
  }

  &[disabled],
  &.isDisabled {
    opacity: 0.4;
    pointer-events: none;
  }

  &--size-input {
    // Minus border top & bottom
    min-height: 44px - 2px;
    padding: 7px 12px;
  }

  @include hover {
    &:before {
      transform: translateX(0);
    }
  }

  &[aria-expanded='true'] {
    &:before {
      transform: translateX(0);
    }
  }
}
.icon-warning {
  font-size: 20px;
  color: color('danger dark');
}
