@import 'variables';

.dropdown-toggle {
  @include theme($theme-customer) {
    white-space: normal !important;
  }
}

.dropdown-menu {
  font-size: 13px;
  border: none;
  padding: 8px 0;
  box-shadow: $box-shadow;
  border-radius: 4px;
  margin-top: 0;
  z-index: index('dropdown-menu');

  .list-dropdown & {
    z-index: index('list-dropdown-menu');
  }

  .dropdown-item {
    margin-bottom: 0;
    font-size: 11px;
    font-weight: 500;
    padding: 10px 8px;
    user-select: none;
    cursor: pointer;
    border-radius: 0px;

    &.active {
      background: $gradient-primary;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
