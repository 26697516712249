@use 'variables' as *;

input,
textarea {
  caret-color: color('primary');
}
ngb-timepicker{
  fieldset {
    border:none;
  }
}
.form {
  &-error-message {
    text-align: center;
    display: block;
    margin-top: 10px;
  }

  &-item {
    & + & {
      margin-top: 16px;
    }
  }

  &-label {
    font-weight: 500;
    margin-bottom: 6px;
    color: color('formLabel');
    font-size: 12px;
    letter-spacing: 0.2px;
    line-height: 16px;
    text-transform: uppercase;

    @include theme($theme-mms) {
      font-weight: 400;
      margin-bottom: 8px;
    }
  }

  &-tip {
    margin-top: 4px;
    color: color('formLabel');
    font-size: 11px;
    line-height: 16px;
  }

  &-validation {
    margin-top: 4px;
    color: color('error');
    font-size: 11px;
  }

  &-value {
    color: color('text');
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.2px;
    line-height: 16px;
    overflow-wrap: break-word;
  }

  &-hidden-input {
    display: block;
    position: absolute;
    z-index: -1;
    bottom: 0;
    left: 0;
    width: 100%;
    opacity: 0;
    pointer-events: none;
  }

  &-item-container {
    display: block;
    cursor: auto;

    &.has-input {
      cursor: pointer;
    }
  }
}
.filters-form{
  padding-top: 30px;
  dm-form-group{
    max-width: 276px;
    width: 100%;
  }
  .double-dates{
    display: flex;
    justify-content: space-between;
    align-items: center;
    & > div {
      width: 114px;
    }
    & > span {
      margin: 0 8px;
    }
  }

}
