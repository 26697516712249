*,
*:after,
*:before {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

html {
	text-size-adjust: 100%;
	-webkit-touch-callout: none;
}

caption {
  text-align: left;
}

body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

iframe {
	border: 0;
}

ol,
ul,
menu {
	list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-size: inherit;
	font-weight: inherit;
}

a {
	display: inline;
	color: inherit;
	text-decoration: none;
}

iframe {
	border: 0;
}

table {
	border-spacing: 0;
}

/* Form Elements */
input {
	appearance: none;
	outline: none;

	&:focus {
		outline: none;
	}
}

[type='text'] {
	&::-ms-clear,
	&::-ms-reveal {
		display: none;
		width: 0;
		height: 0;
	}
}

[type='number'] {
	appearance: textfield;
}

[type='search'] {
	&::-webkit-search-cancel-button {
		appearance: none;
	}
}

//  Hide original input element
	[type='checkbox'],
[type='radio'] {
	position: absolute !important;
	bottom: 0 !important;
	left: 50% !important;
	width: 1px !important;
	height: 1px !important;
	padding: 0 !important;
	overflow: hidden !important;
	clip: rect(0 0 0 0) !important;
	border: 0 !important;
	white-space: nowrap !important;
	clip-path: inset(50%) !important;
}

button {
	border: 0;
	outline: none;
	background: 0 0;
	color: inherit;
	font-family: inherit;
	font-size: inherit;
	font-weight: inherit;
	text-align: left;
	cursor: pointer;
	appearance: button;
}

label {
	[for] {
		cursor: pointer;
	}
}

address {
	font-style: normal;
}

video {
	width: 100%;
}

hr {
	display: block;
	width: 100%;
	border: 0;
}
