@use 'sass:map';

@mixin square($width, $height: $width) {
  width: $width;
  height: $height;
}

@mixin wrap($max-width, $space-aside: 0) {
  @if $space-aside != 0 {
    max-width: $max-width + $space-aside * 2;
    padding-right: $space-aside;
    padding-left: $space-aside;
  }

 @else {
    max-width: $max-width;
  }
}

@mixin wrap-style {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

@mixin edit-form-page($padding-top: 40px) {
  display: block;
  padding: $padding-top 20px 48px;
}

@mixin list-component() {
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: stretch;
}

@mixin bg-image(
  $filename,
  $size: cover,
  $position: center,
  $repeat: no-repeat
) {
  @if $filename != '' {
    background-image: url('/assets/images/#{$filename}');
  }

  background-position: $position;
  background-size: $size;
  background-repeat: $repeat;
}

@mixin bg-image-retina($filename, $extension) {
  $retina: 'only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx)';

  @include bg-image('');

  background-image: url('/assets/images/#{$filename}.#{$extension}');

  @media #{$retina} {
    background-image: url('/assets/images/#{$filename}@2x.#{$extension}');
  }
}

@mixin overlay() {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@mixin cover() {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@mixin hover($canParentTrigger: false) {
  @media (hover: hover) {
    &:hover {
      @content;
    }
  }

  @if $canParentTrigger == true {
    @media (hover: hover) {
      &--hover:hover {
        @content;
      }
    }
  }
}

@mixin multiline-ellipsis($font-size, $line-height, $lines-to-show) {
  display: box;
  height: $font-size * $line-height * $lines-to-show;
  font-size: $font-size;
  -webkit-line-clamp: $lines-to-show;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: $line-height;
  text-overflow: ellipsis;
}

@mixin placeholder() {
  &::input-placeholder {
    @content;
  }

  &:placeholder {
    @content;
  }

  &::placeholder {
    @content;
  }

  &:input-placeholder {
    @content;
  }
}

@mixin input-autocomplete($bg: white, $color: $primary) {
  &:-webkit-autofill {
    box-shadow: inset 0 0 0 30px $bg;
    -webkit-text-fill-color: $color;
  }
}

@mixin content-link {
  color: $blue;
  text-decoration: underline;
  text-decoration-color: $blue;
}

@mixin font-face(
  $name,
  $path,
  $weight: null,
  $style: null,
  $exts: eot woff2 woff ttf
) {
  $src: null;
  $extmods: (
    eot: '?',
    svg: '#' + str-replace($name, ' ', '_'),
  );
  $formats: (
    otf: 'opentype',
    ttf: 'truetype',
  );

  @each $ext in $exts {
    $extmod: if(
        map-has-key($extmods, $ext),
        $ext + map-get($extmods, $ext),
        $ext
    );
    $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
    $src: append(
        $src,
        url(quote($path + '.' + $extmod)
) format(quote($format)),
        comma
    );
  }

  @font-face {
    font-family: quote($name);
    font-style: $style;
    font-weight: $weight;
    src: $src;
  }
}

@mixin multiLineEllipsis($lineHeight: 1.2em, $lineCount: 1, $bgColor: white) {
  overflow: hidden;
  position: relative;
  line-height: $lineHeight;
  max-height: $lineHeight * $lineCount;
  margin-right: -1em;
  padding-right: 1em;

  &:before {
    content: '...';
    position: absolute;
    right: 0;
    bottom: 0;
  }

  &:after {
    content: '';
    position: absolute;
    right: 0;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    background: $bgColor;
  }
}

@mixin click-expand($space) {
  &:before {
    width: calc(100% + #{$space * 2});
    height: calc(100% + #{$space * 2});
  }
}

@mixin click-expand-style {
  position: relative;
  z-index: 0;

  &:before {
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }
}

@mixin headings {
  h1,
 h2,
 h3,
  h4,
 h5,
 h6 {
    @content;
  }
}

 @mixin btn-style($name, $bgColor, $textColor: white) {
   .btn-#{$name} {
     color: $textColor;
     background-color: $bgColor;

     &:hover,
 &:focus {
       background-color: darken($bgColor, 5) !important;
       outline: 0 !important;
       box-shadow: none !important;
     }

     &:active {
       background-color: darken($bgColor, 10) !important;
     }

     &.btn-border {
       border: 1px solid $bgColor;
       color: $bgColor;
       background: white;

       &:hover,
 &:focus {
         background-color: $bgColor;
         outline: 0 !important;
         color: white;
         box-shadow: none !important;
       }

       &:active {
         background-color: darken($bgColor, 10) !important;
       }
     }

     //&:disabled {
     //  color: $bgColor;
     //  background:;
     //  border: 2px solid $disableBg;
     //
     //  &:hover, &:active {
     //    background: $disableBg !important;
     //    color: $bgColor !important;
     //    text-decoration: none;
     //  }
     //}
   }
 }
