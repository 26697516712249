@use 'variables' as *;

html {
  color: color('text');
  font-family: font('default');

  @include theme($theme-mms) {
    margin-right: calc(-1 * (100vw - 100%));
  }
}

body {
  transition: background-color .3s ease-in-out;

  @include theme($theme-customer) {
    background-color: color('bg');
  }

  &.full-page {
    overflow: hidden;
  }

  // TODO: move app component theme service
  @include theme($theme-customer) {
    &.bg-dark {
      background-color: color('bgDark') !important;
    }
  }

  // slideRouteAnimation
  &:not(.modal-open) {
    @include theme($theme-customer) {
      overflow-x: hidden;
    }
  }
}
