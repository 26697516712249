@use 'variables' as *;
@use 'node_modules/@ng-select/ng-select/scss/_mixins.scss' as *;

$ng-select-highlight: color('primary') !default;
$ng-select-primary-text: color('text') !default;
$ng-select-disabled-text: #f9f9f9 !default;
$ng-select-border: color('formBorder') !default;
$ng-select-border-radius: 4px !default;
$ng-select-bg: #fff !default;
$ng-select-selected: color('primary') !default;
$ng-select-selected-text: color('white') !default;
$ng-select-marked: lighten($ng-select-highlight, 35) !default;
$ng-select-marked-text: $ng-select-primary-text !default;
$ng-select-box-shadow: null !default;
$ng-select-placeholder: lighten($ng-select-primary-text, 40) !default;
$ng-select-height: 44px !default;
$ng-select-value-padding-left: 12px !default;
$ng-select-value-font-size: 14px !default;
$ng-select-value-text: $ng-select-primary-text !default;
$ng-select-dropdown-bg: $ng-select-bg !default;
$ng-select-dropdown-border: color('primary') !default;
$ng-select-dropdown-optgroup-text: rgb(0 0 0 / 54%) !default;
$ng-select-dropdown-optgroup-marked: $ng-select-dropdown-optgroup-text !default;
$ng-select-dropdown-option-bg: $ng-select-dropdown-bg !default;
$ng-select-dropdown-option-text: rgb(0 0 0 / 87%) !default;
$ng-select-dropdown-option-disabled: color('text');
$ng-select-input-text: color('text');

.ng-select {
  font-weight: 500;

  // .ng-value {
  //   display: flex;
  //   align-items: center;
  // }

  &.ng-select-opened {
    > .ng-select-container {
      border-color: color('primary');
      background: $ng-select-bg;

      &:hover {
        box-shadow: none;
      }

      .ng-arrow {
        top: -2px;
        border-width: 0 5px 5px;
        border-color: transparent transparent color('primary');

        &:hover {
          border-color: transparent transparent darken($ng-select-border, 60);
        }
      }
    }

    &.ng-select-top {
      > .ng-select-container {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }

    &.ng-select-right {
      > .ng-select-container {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    &.ng-select-bottom {
      > .ng-select-container {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    &.ng-select-left {
      > .ng-select-container {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }

  &.ng-select-focused {
    &:not(.ng-select-opened) > .ng-select-container {
      border-color: $ng-select-highlight;
      box-shadow: $ng-select-box-shadow;
    }

    .ng-arrow {
      border-color: color('primary') transparent transparent;
    }
  }

  &.ng-select-disabled {
    > .ng-select-container {
      background-color: $ng-select-disabled-text;
    }
  }

  .ng-has-value .ng-placeholder {
    display: none;
  }

  .ng-select-container {
    align-items: center;
    min-height: $ng-select-height;
    transition: border $time-transition;
    border: 1px solid $ng-select-border;
    border-radius: $ng-select-border-radius;
    background-color: $ng-select-bg;
    color: $ng-select-primary-text;
    font-size: $ng-select-value-font-size;
    letter-spacing: 0.2px;

    &:hover {
      box-shadow: 0 1px 0 rgb(0 0 0 / 6%);
    }

    .ng-value-container {
      .ng-placeholder {
        color: $ng-select-placeholder;
      }

      @include rtl {
        padding-right: $ng-select-value-padding-left;
        padding-left: 0;
      }
    }
  }

  &.ng-select-single {
    .ng-select-container {
      height: $ng-select-height;

      .ng-value-container {
        .ng-input {
          top: 50%;
          left: 0;
          padding-right: 50px;
          padding-left: $ng-select-value-padding-left;

          @include rtl {
            padding-right: $ng-select-value-padding-left;
            padding-left: 50px;
          }
        }
      }
    }
  }

  &.ng-select-multiple {
    &.ng-select-disabled {
      > .ng-select-container .ng-value-container .ng-value {
        border: 1px solid lighten($ng-select-border, 10);
        background-color: $ng-select-disabled-text;

        .ng-value-label {
          padding: 0 5px;
        }
      }
    }

    .ng-select-container {
      .ng-value-container {
        padding-top: 5px;
        padding-left: 7px;

        .ng-value {
          margin-right: 5px;
          margin-bottom: 5px;
          border-radius: 2px;
          background-color: $ng-select-selected;
          color: $ng-select-value-text;
          font-size: $ng-select-value-font-size;
          letter-spacing: 0.2px;

          &.ng-value-disabled {
            background-color: $ng-select-disabled-text;

            .ng-value-label {
              padding-left: 5px;

              @include rtl {
                padding-right: 5px;
                padding-left: 0;
              }
            }
          }

          .ng-value-label {
            display: inline-block;
            padding: 1px 5px;
          }

          .ng-value-icon {
            display: inline-block;
            padding: 1px 5px;

            &:hover {
              background-color: darken($ng-select-selected, 5);
            }

            &.left {
              border-right: 1px solid darken($ng-select-selected, 10);

              @include rtl {
                border-right: none;
                border-left: 1px solid darken($ng-select-selected, 10);
              }
            }

            &.right {
              border-left: 1px solid darken($ng-select-selected, 10);

              @include rtl {
                border-right: 1px solid darken($ng-select-selected, 10);
                border-left: 0;
              }
            }
          }

          @include rtl {
            margin-right: 0;
            margin-left: 5px;
          }
        }

        .ng-input {
          padding: 0 0 3px 3px;

          > input {
            color: $ng-select-input-text;
          }

          @include rtl {
            padding: 0 3px 3px 0;
          }
        }

        .ng-placeholder {
          top: 5px;
          padding-bottom: 5px;
          padding-left: 3px;

          @include rtl {
            padding-right: 3px;
            padding-left: 0;
          }
        }

        @include rtl {
          padding-right: 7px;
          padding-left: 0;
        }
      }
    }
  }

  .ng-clear-wrapper {
    text-align: center;
    color: darken($ng-select-border, 20);

    &:hover .ng-clear {
      color: #d0021b;
    }
  }

  .ng-spinner-zone {
    padding: 5px 5px 0 0;

    @include rtl {
      padding: 5px 0 0 5px;
    }
  }

  .ng-arrow-wrapper {
    width: 25px;
    padding-right: 5px;

    &:hover {
      .ng-arrow {
        border-top-color: darken($ng-select-border, 40);
      }
    }

    .ng-arrow {
      border-width: 5px 5px 2.5px;
      border-style: solid;
      border-color: $ng-select-highlight transparent transparent;
    }

    @include rtl {
      padding-right: 0;
      padding-left: 5px;
    }
  }
}

.ng-dropdown-panel {
  left: 0;
  border: 1px solid $ng-select-dropdown-border;
  background-color: $ng-select-dropdown-bg;
  box-shadow: 0 1px 0 rgb(0 0 0 / 6%);

  &.ng-select-top {
    bottom: 100%;
    margin-bottom: -1px;
    border-top-left-radius: $ng-select-border-radius;
    border-top-right-radius: $ng-select-border-radius;
    border-bottom-color: lighten($ng-select-border, 10);

    .ng-dropdown-panel-items {
      .ng-option {
        &:first-child {
          border-top-left-radius: $ng-select-border-radius;
          border-top-right-radius: $ng-select-border-radius;
        }
      }
    }
  }

  &.ng-select-right {
    top: 0;
    left: 100%;
    margin-bottom: -1px;
    border-top-right-radius: $ng-select-border-radius;
    border-bottom-right-radius: $ng-select-border-radius;
    border-bottom-left-radius: $ng-select-border-radius;
    border-bottom-color: lighten($ng-select-border, 10);

    .ng-dropdown-panel-items {
      .ng-option {
        &:first-child {
          border-top-right-radius: $ng-select-border-radius;
        }
      }
    }
  }

  &.ng-select-bottom {
    top: 100%;
    margin-top: -1px;
    border-bottom-right-radius: $ng-select-border-radius;
    border-bottom-left-radius: $ng-select-border-radius;
    border-top-color: lighten($ng-select-border, 10);

    .ng-dropdown-panel-items {
      .ng-option {
        &:last-child {
          border-bottom-right-radius: $ng-select-border-radius;
          border-bottom-left-radius: $ng-select-border-radius;
        }
      }
    }
  }

  &.ng-select-left {
    top: 0;
    left: -100%;
    margin-bottom: -1px;
    border-top-left-radius: $ng-select-border-radius;
    border-bottom-right-radius: $ng-select-border-radius;
    border-bottom-left-radius: $ng-select-border-radius;
    border-bottom-color: lighten($ng-select-border, 10);

    .ng-dropdown-panel-items {
      .ng-option {
        &:first-child {
          border-top-left-radius: $ng-select-border-radius;
        }
      }
    }
  }

  .ng-dropdown-header {
    padding: 5px 7px;
    border-bottom: 1px solid $ng-select-border;
  }

  .ng-dropdown-footer {
    padding: 5px 7px;
    border-top: 1px solid $ng-select-border;
  }

  .ng-dropdown-panel-items {
    .ng-optgroup {
      padding: 8px 10px;
      color: $ng-select-dropdown-optgroup-text;
      font-weight: 500;
      cursor: pointer;
      user-select: none;

      &.ng-option-disabled {
        cursor: default;
      }

      &.ng-option-marked {
        background-color: $ng-select-marked;
      }

      &.ng-option-selected,
      &.ng-option-selected.ng-option-marked {
        background-color: $ng-select-selected;
        color: $ng-select-dropdown-optgroup-marked;
        font-weight: 600;
      }
    }

    .ng-option {
      padding: 8px 10px;
      background-color: $ng-select-dropdown-option-bg;
      color: $ng-select-dropdown-option-text;
      font-size: $ng-select-value-font-size;
      letter-spacing: 0.2px;

      &.ng-option-selected,
      &.ng-option-selected.ng-option-marked {
        background-color: $ng-select-selected;
        color: $ng-select-selected-text;

        .ng-option-label {
          font-weight: 600;
        }
      }

      &.ng-option-marked {
        background-color: $ng-select-marked;
        color: $ng-select-marked-text;
      }

      &.ng-option-disabled {
        color: $ng-select-dropdown-option-disabled;
      }

      &.ng-option-child {
        padding-left: 22px;

        @include rtl {
          padding-right: 22px;
          padding-left: 0;
        }
      }

      .ng-tag-label {
        padding-right: 5px;
        font-size: 80%;
        font-weight: 400;

        @include rtl {
          padding-right: 0;
          padding-left: 5px;
        }
      }
    }
  }

  @include rtl {
    direction: rtl;
    text-align: right;
  }
}
