@use 'variables' as *;

.wrap {
  @include wrap-style;

  &--small {
    @include theme($theme-customer) {
      @include wrap(800, 15px);
    }
  }

  &--default {
    @include theme($theme-customer) {
      @include wrap(1200, 15px);
    }

    @include theme($theme-mms) {
      @include wrap(1200, 15px);
    }

    @include theme($theme-booking) {
      @include wrap(712, 16px)
    }
  }

  &--big {
    @include theme($theme-booking) {
      @include wrap(900, 16px)
    }
  }
}
