@font-face {font-family: "dmarin-font-icons";
  src: url('^assets/fonts/dmarin-font-icons.eot?t=1738246282655'); /* IE9*/
  src: url('^assets/fonts/dmarin-font-icons.eot?t=1738246282655#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url("^assets/fonts/dmarin-font-icons.woff2?t=1738246282655") format("woff2"),
  url("^assets/fonts/dmarin-font-icons.woff?t=1738246282655") format("woff"),
  url('^assets/fonts/dmarin-font-icons.ttf?t=1738246282655') format('truetype'), /* chrome, firefox, opera, Safari, Android, iOS 4.2+*/
  url('^assets/fonts/dmarin-font-icons.svg?t=1738246282655#dmarin-font-icons') format('svg'); /* iOS 4.1- */
}

[class^="i-"], [class*=" i-"] {
  font-family: 'dmarin-font-icons' !important;font-size: undefined;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 1;
  text-transform: none;
  speak: never
}

.i-addbooking:before { content: "\ea01"; }
.i-anchor-2:before { content: "\ea02"; }
.i-anchor:before { content: "\ea03"; }
.i-arrow-depth:before { content: "\ea04"; }
.i-arrow-double-right:before { content: "\ea05"; }
.i-arrow-export:before { content: "\ea06"; }
.i-arrow-left:before { content: "\ea07"; }
.i-arrow-right:before { content: "\ea08"; }
.i-arrow-x:before { content: "\ea09"; }
.i-arrow-y:before { content: "\ea0a"; }
.i-bank:before { content: "\ea0b"; }
.i-berths:before { content: "\ea0c"; }
.i-bilge:before { content: "\ea0d"; }
.i-bill:before { content: "\ea0e"; }
.i-bin:before { content: "\ea0f"; }
.i-boat-2:before { content: "\ea10"; }
.i-boat-front:before { content: "\ea11"; }
.i-boat:before { content: "\ea12"; }
.i-book:before { content: "\ea13"; }
.i-booking:before { content: "\ea14"; }
.i-business:before { content: "\ea15"; }
.i-calendar-add:before { content: "\ea16"; }
.i-calendar:before { content: "\ea17"; }
.i-card-2:before { content: "\ea18"; }
.i-card:before { content: "\ea19"; }
.i-catamaran:before { content: "\ea1a"; }
.i-check-circle:before { content: "\ea1b"; }
.i-check-small:before { content: "\ea1c"; }
.i-check:before { content: "\ea1d"; }
.i-chevron-left:before { content: "\ea1e"; }
.i-chevron_down:before { content: "\ea1f"; }
.i-close-circle:before { content: "\ea20"; }
.i-close:before { content: "\ea21"; }
.i-cloud:before { content: "\ea22"; }
.i-coins:before { content: "\ea23"; }
.i-comment:before { content: "\ea24"; }
.i-compass:before { content: "\ea25"; }
.i-contract:before { content: "\ea26"; }
.i-copy:before { content: "\ea27"; }
.i-diamond:before { content: "\ea28"; }
.i-dmarin-logo-signet:before { content: "\ea29"; }
.i-document-2:before { content: "\ea2a"; }
.i-document:before { content: "\ea2b"; }
.i-documents:before { content: "\ea2c"; }
.i-download:before { content: "\ea2d"; }
.i-drop-outline:before { content: "\ea2e"; }
.i-drop:before { content: "\ea2f"; }
.i-dropdown-thin:before { content: "\ea30"; }
.i-dropdown:before { content: "\ea31"; }
.i-edit:before { content: "\ea32"; }
.i-envelope-notification:before { content: "\ea33"; }
.i-envelope:before { content: "\ea34"; }
.i-exclamation-mark-circle:before { content: "\ea35"; }
.i-eye-invisible:before { content: "\ea36"; }
.i-eye:before { content: "\ea37"; }
.i-form-info:before { content: "\ea38"; }
.i-gear:before { content: "\ea39"; }
.i-globe:before { content: "\ea3a"; }
.i-height:before { content: "\ea3b"; }
.i-home:before { content: "\ea3c"; }
.i-image-download:before { content: "\ea3d"; }
.i-info-2:before { content: "\ea3e"; }
.i-info:before { content: "\ea3f"; }
.i-invisible:before { content: "\ea40"; }
.i-lightning:before { content: "\ea41"; }
.i-list:before { content: "\ea42"; }
.i-logo:before { content: "\ea43"; }
.i-mail-2:before { content: "\ea44"; }
.i-mail-notification:before { content: "\ea45"; }
.i-mail:before { content: "\ea46"; }
.i-marina-task:before { content: "\ea47"; }
.i-mobile:before { content: "\ea48"; }
.i-money-notification:before { content: "\ea49"; }
.i-money-refresh:before { content: "\ea4a"; }
.i-money-tick:before { content: "\ea4b"; }
.i-mooring:before { content: "\ea4c"; }
.i-more-squares:before { content: "\ea4d"; }
.i-more:before { content: "\ea4e"; }
.i-motorboat:before { content: "\ea4f"; }
.i-office:before { content: "\ea50"; }
.i-out:before { content: "\ea51"; }
.i-outside_link:before { content: "\ea52"; }
.i-phone:before { content: "\ea53"; }
.i-pin:before { content: "\ea54"; }
.i-plug-in:before { content: "\ea55"; }
.i-plus-circle:before { content: "\ea56"; }
.i-plus:before { content: "\ea57"; }
.i-pricing:before { content: "\ea58"; }
.i-profile:before { content: "\ea59"; }
.i-radio:before { content: "\ea5a"; }
.i-refresh:before { content: "\ea5b"; }
.i-sailing-boat:before { content: "\ea5c"; }
.i-search:before { content: "\ea5d"; }
.i-send:before { content: "\ea5e"; }
.i-service:before { content: "\ea5f"; }
.i-star-outline:before { content: "\ea60"; }
.i-star-plus:before { content: "\ea61"; }
.i-star:before { content: "\ea62"; }
.i-success:before { content: "\ea63"; }
.i-summary-2:before { content: "\ea64"; }
.i-summary:before { content: "\ea65"; }
.i-temperature:before { content: "\ea66"; }
.i-time:before { content: "\ea67"; }
.i-timer:before { content: "\ea68"; }
.i-transfer:before { content: "\ea69"; }
.i-upload:before { content: "\ea6a"; }
.i-user-2:before { content: "\ea6b"; }
.i-user-corporate:before { content: "\ea6c"; }
.i-user-full:before { content: "\ea6d"; }
.i-user:before { content: "\ea6e"; }
.i-users:before { content: "\ea6f"; }
.i-wallet-2:before { content: "\ea70"; }
.i-wallet:before { content: "\ea71"; }
.i-width:before { content: "\ea72"; }

