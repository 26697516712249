@use 'variables' as *;
ngb-timepicker {
  .btn-link {
    color: color('primary');
    will-change: color;
    transition: color .25s ease-in-out;
    &:before {
      display: none !important;

    }
    &:hover{
      color: darken(color('primary'), 20%) !important;
    }
  }
}
