@forward './../../global/variables';

@use './../../global/variables' as *;

$color-brown: #b96f54;
$color-primary: $color-brown;
$color-secondary: #126cea;
$color-black: #1b253a;
$color-gray-light: #e8f2fe;
$color-iron: #041e42;
$color-kashmir: #506a8d;
$color-gray: #6886a7;
$color-baltic: #8da8d2;
$color-mist: #c2d1e9;
$color-smoke: #dae2eb;
$color-gray-lighter: #f5f7fa;
$color-shy: #f1f6fc;
$color-alabaster: #f8fafd;
$color-red: #f33;
$color-red-dark: #d90000;
$gradient-primary: linear-gradient(315deg, #b96f54 0%, #e09d85 100%);
$gradient-secondary: linear-gradient(95.95deg, #0091ff 33.08%, #003bd3 106.23%);
$color-green: #32c65c;
$color-silver: #b7b8be;
$color-yellow: #ffc535;

@include define-colors(
  (
    'primary': $color-primary,
    'secondary': $color-secondary,
    'formBorder': $color-smoke,
    'formLabel': $color-baltic,
    'text': $color-iron,
    'text secondary': $color-kashmir,
    'black': $color-black,
    'navTabsColor': $color-mist,
    'white': white,
    'grey': $color-gray,
    'grey light': $color-smoke,
    'error': $color-red,
    'danger': $color-red,
    'danger dark': $color-red-dark,
    'success': $color-green,
    'bg': $color-alabaster,
    'bgDark': $color-iron,
    'gradient primary': $gradient-primary,
    'gradient secondary': $gradient-secondary,
    'input background disabled': $color-shy,
    'tooltip background': $color-kashmir,
    'form-file link': $color-kashmir,
    'silver': $color-silver,
    'baltic': $color-baltic,
    'star': $color-yellow,
  )
);
